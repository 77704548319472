import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "./shared/config/context";
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <Provider>
    <App />
  </Provider>,
  document.getElementById("root")
);

// Don't use a service worker for now, will cause a nightmare with
// testing old versions
//serviceWorkerRegistration.register();

// Clear out the old service worker
/*async function deleteCaches() {
  try {
    const keys = await window.caches.keys()
    await Promise.all(keys.map(key => caches.delete(key)))
  } catch (err) {
    console.log('deleteCache err: ', err)
  }
}*/

//deleteCaches()

/*navigator.serviceWorker.getRegistrations().then(function(registrations) {
 for(let registration of registrations) {
  registration.unregister()
} })*/
