import React from "react";
import { useBlockUIContext } from "./BlockUI";
import { graphQLCall, createNotification } from "./../config/axios";

const { createContext, useContext } = React;

const Context = createContext({});
export const useRequestsContext = () => useContext(Context);

const Requests = (props) => {
  const { children, classes, ...otherProps } = props;
  const { setBlockUI } = useBlockUIContext();

  const child = React.cloneElement(children, otherProps);

  const callGraphQLLocal = async (query, variables) => {
    const cacheKey =
      "graphQLResponse_" + btoa(query + JSON.stringify(variables));
    const cachedData = localStorage.getItem(cacheKey);
    const now = new Date();

    if (cachedData) {
      const { timestamp, result } = JSON.parse(cachedData);
      const age = now - new Date(timestamp);
      if (age < 24 * 60 * 60 * 1000) {
        // Cache for 24 hours
        return { result, success: true, fromCache: true };
      }
    }

    setBlockUI(true);
    try {
      var response = await graphQLCall(query, variables);
      if (response.status != 200) {
        setBlockUI(false);
        createNotification("error", "Error fetching car data.");
        return { success: false };
      }
      var result = await response.json();
      console.log("result", result);

      // Save to cache
      localStorage.setItem(
        cacheKey,
        JSON.stringify({ timestamp: now, result })
      );
    } catch (err) {
      setBlockUI(false);
      createNotification("error", err.message);
      throw err;
    }
    setBlockUI(false);

    var { errors } = result;
    if (errors) {
      createNotification("error", errors[0].message);
      return { success: false, result };
    }

    return {
      result,
      success: true,
    };
  };

  return (
    <Context.Provider
      value={{
        graphQLCall: callGraphQLLocal,
      }}
    >
      {child}
    </Context.Provider>
  );
};

export default Requests;
