import React, { Fragment, Suspense, lazy } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
//import Pace from "./shared/components/Pace";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { timeOut } from "./shared/config/axios";
import BlockUI from "./shared/hoc/BlockUI";
import Requests from "./shared/hoc/Requests";
import Modal from "./shared/hoc/Modal";
import RoutingListener from "./shared/hoc/RoutingListener";
import NewsletterPopupHOC from "./shared/hoc/NewsletterPopup";
import { StacksProvider } from "@mobily/stacks";
import { SafeAreaProvider } from "react-native-safe-area-context";
// import { hot } from "react-hot-loader/root";

import { InstallReactNativeVectorIcons } from "./components/InstallReactNativeVectorIcons";
const MainComponent = lazy(() => import("./pages/Main"));

function App() {
  return (
    <BrowserRouter>
      <SafeAreaProvider>
        <InstallReactNativeVectorIcons />
        <StacksProvider spacing={4}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />
            {/*<Pace color={theme.palette.primary.light} />*/}
            <Suspense fallback={<Fragment />}>
              <Switch>
                <Route>
                  <ToastContainer
                    position="bottom-center"
                    autoClose={timeOut}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  <BlockUI>
                    <Modal>
                      <Requests>
                        <NewsletterPopupHOC>
                          <RoutingListener>
                            <MainComponent />
                          </RoutingListener>
                        </NewsletterPopupHOC>
                      </Requests>
                    </Modal>
                  </BlockUI>
                </Route>
              </Switch>
            </Suspense>
          </MuiThemeProvider>
        </StacksProvider>
      </SafeAreaProvider>
    </BrowserRouter>
  );
}

export default App;
