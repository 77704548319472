import React, { createContext, useState, useEffect, useContext } from "react";
import { useAnalyticsEvents } from "../../hooks/useAnalyticsEvents";
import {
  createNotification,
  carMakeList,
  profileDetails,
  appreciatingClassicCar,
  addCarWatchList,
  myCar,
  allCarWatchList,
  allMyCar,
  graphQLCall,
} from "../config/axios";
import { noRedirect, notificationMsg } from "../config/constants";
export const Context = createContext();

// TODO Seems bad practice loading all this data on load, would probably
// make more sense to load data when its needed.
export const useInitialDataContext = () => useContext(Context);

export const Provider = (props) => {
  const [carCompany, setCarCompany] = useState("0");
  const [loggedinFlag, setLoggedinFlag] = useState(false);
  const [watchListFlag, setWatchListFlag] = useState(false);
  const [myCarFlag, setMyCarFlag] = useState(false);
  const [user, setUser] = useState(null);
  const [totalMyCars, setTotalMyCars] = useState(0);
  const [totalMyWatchlist, setTotalMyWatchlist] = useState(0);
  const [userUpdateValue, setUserUpdateValue] = useState({});
  const [histroyData, setHistroyData] = useState({});
  const [carMakeData, setCarMakeData] = useState([]);
  const [watchlistcar, setWatchlistCar] = useState([]);
  const [mycar, setMyCar] = useState([]);
  const [mycardemand, setMyCarDemand] = useState([]);
  const [myCarAnalytics, setMyCarAnalytics] = useState([]);
  const [trendGraphData, setTrendGraphData] = useState({});
  const [appreciatingClassicCarList, setAppreciatingClassicCar] = useState([]);
  const [
    appreciatingClassicCarListImageList,
    setAppreciatingClassicCarImageList,
  ] = useState([]);
  const [
    appreciatingClassicCarListNameList,
    setAppreciatingClassicCarNameList,
  ] = useState([]);
  const [isDealer, setIsDealer] = useState(null);
  const [authenticateRedirectPath, setAuthenticateRedirectPath] =
    useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const { getAnalyticsEvents } = useAnalyticsEvents();
  const [firstFetchDone, setFirstFetchDone] = useState(false);
  const [isLoadingMake, setIsLoadingMake] = useState(false);

  useEffect(() => {
    var accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      // Check if the access token has expired, if it has delete it
      try {
        var payloadPart = accessToken.split(".")[1];
        var payload = JSON.parse(window.atob(payloadPart));
        var expiresAt = new Date(payload.exp * 1000);
        var now = new Date();
        if (expiresAt < now) {
          localStorage.clear();
          return;
        }
      } catch (e) {
        console.error(e);
      }
      setLoggedinFlag(true);
    }
  });

  useEffect(() => {
    if (loggedinFlag && !watchListFlag) {
      allWatchlist();
    }
  }, [loggedinFlag, !watchListFlag]);

  const allWatchlist = async () => {
    await allCarWatchList()
      .then((resp) => {
        if (resp.data.status) {
          setWatchlistCar(resp.data.data);
          setWatchListFlag(true);
        }
      })
      .catch(function (error) {
        let err = { error };
        if (
          err &&
          err.error &&
          err.error.response &&
          err.error.response.data &&
          !err.error.response.data.status &&
          err.error.response.data.error
        ) {
          if (typeof err.error.response.data.error == "string") {
            createNotification("error", err.error.response.data.error);
          } else {
            createNotification("error", err.error.response.data.error.message);
          }
        } else {
          createNotification("error", notificationMsg.error);
        }
      });
  };

  useEffect(() => {
    if (loggedinFlag && !myCarFlag) {
      allCarList();
    }
  }, [loggedinFlag, !myCarFlag]);

  const allCarList = async () => {
    console.log("allCarList is called!!");
    await allMyCar()
      .then((resp) => {
        if (resp.data.status) {
          // Sort newest cars to the top
          resp.data.data.sort((a, b) => {
            // Old cars have a timestamp as a date value whereas new
            // cars have a iso string, so we convert to a date here to
            // iron out any differences
            var dateA = new Date(a.date_modified || a.date_created);
            var dateB = new Date(b.date_modified || b.date_created);
            if (dateA < dateB) return 1;
            if (dateA > dateB) return -1;
            return 0;
          });
          setMyCar(resp.data.data);

          getAnalyticsEvents(resp.data.data.map((item) => item._id) ?? []).then(
            (data) => {
              setMyCarAnalytics(data);
            }
          );

          setMyCarDemand(resp.data.demand_count);
          setMyCarFlag(true);
          console.log(resp.data.data);
        }
      })
      .catch(function (error) {
        let err = { error };
        if (
          err &&
          err.error &&
          err.error.response &&
          err.error.response.data &&
          !err.error.response.data.status &&
          err.error.response.data.error &&
          typeof err.error.response.data.error == "string"
        ) {
          createNotification("error", err.error.response.data.error);
        } else if (error && error.message) {
          createNotification("error", error.message);
        } else {
          createNotification("error", notificationMsg.error);
        }
      });
  };

  const refetchMyCars = () => {
    setMyCar([]);
    setMyCarDemand([]);
    setMyCarFlag([]);
    allCarList();
  };

  useEffect(() => {
    getDetails().then(() => setFirstFetchDone(true));
  }, []);

  const getDetails = async () => {
    // Without this there will be a constant loop of refetching user details
    if (user) return;
    // Commenting out as it leads to bug
    // https://www.pivotaltracker.com/story/show/182660523
    // https://trello.com/c/0ljG8Pjs/422-mktpl-user-chose-price-trend-sees-marketplace-below
    //if (!noRedirect.includes(window.location.pathname)) {
    if (
      localStorage.getItem("access_token") // &&
      //localStorage.getItem("refresh_token")
    ) {
      await fetchProfileDetails();
    }
    //}
  };

  const fetchProfileDetails = () => {
    setIsLoadingUser(true);
    return profileDetails().then((resp) => {
      if (resp.data.status) {
        const userData = resp.data.data;
        setIsLoadingUser(false);
        setUser(userData);
        setUserUpdateValue(userData);
        setIsDealer(userData.isDealer === 1);
      }
    });
  };

  useEffect(() => {
    setIsLoadingMake(true);
    carMakeList()
      .then((resp) => {
        setIsLoadingMake(false);
        if (resp.data.status) {
          setCarMakeData(resp.data.data);
        }
      })
      .catch(() => {
        setIsLoadingMake(false);
      });
  }, [carMakeData.length]);

  useEffect(() => {
    appreciatingClassicCar().then((resp) => {
      if (resp.data.status) {
        setAppreciatingClassicCar(resp.data.data);
        setAppreciatingClassicCarImageList(resp.data.imageList);
        setAppreciatingClassicCarNameList(resp.data.nameList);
      }
    });
  }, [appreciatingClassicCarList.length]);

  return (
    <Context.Provider
      value={{
        // Code had this set as an object by default so there's probably some
        // code somewhere that expects this to always be an object
        user: user || {},
        user2: user,
        mycar,
        mycardemand,
        myCarAnalytics,
        carCompany,
        histroyData,
        carMakeData,
        totalMyCars,
        loggedinFlag,
        watchlistcar,
        trendGraphData,
        userUpdateValue,
        totalMyWatchlist,
        appreciatingClassicCarList,
        appreciatingClassicCarListImageList,
        appreciatingClassicCarListNameList,
        authenticateRedirectPath,
        setAuthenticateRedirectPath,
        setUser,
        setMyCar,
        setMyCarDemand,
        setCarCompany,
        setHistroyData,
        setTotalMyCars,
        setWatchlistCar,
        setLoggedinFlag,
        setTrendGraphData,
        setUserUpdateValue,
        setTotalMyWatchlist,
        // advertisementData,
        refetchMyCars,
        isDealer,
        fetchProfileDetails,
        firstFetchDone,
        setCarMakeData,
        isLoadingUser,
        isLoadingMake,
        allWatchlist,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
