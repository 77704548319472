import React from "react";
import { useHistory } from "react-router-dom";

const { useEffect } = React;

const RoutingListener = (props) => {
  const { children, ...otherProps } = props;
  const history = useHistory();

  useEffect(() => {
    history.listen((route, navType) => {
      if (navType == "PUSH") window.scrollTo(0, 0);
    });
  }, []);

  const child = React.cloneElement(children, otherProps);
  return child;
};

export default RoutingListener;
