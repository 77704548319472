import React from "react";
import { withStyles } from "@material-ui/styles";
import NewsletterPopUp from "../components/NewsletterPopup";
//import { Context as MainContext } from "../../shared/config/context"
import { useRequestsContext } from "./Requests";
import { useModalContext } from "./Modal";

const { createContext, useContext, useEffect, useState } = React;

const Context = createContext({});
export const useNewsletterPopupContext = () => useContext(Context);

const NewsletterPopupHOC = (props) => {
  const { children, classes, ...otherProps } = props;
  const [newsletterPopup, setNewsletterVisible] = useState(false);
  const { graphQLCall } = useRequestsContext();
  const { showModal } = useModalContext();

  const child = React.cloneElement(children, otherProps);
  //const mainContext = useContext(MainContext);

  // Bit of a hack but the user object is always populated when it shouldn't be
  //const user = Object.keys(mainContext.user).length ? mainContext.user : null;

  useEffect(() => {
    // If there is no user logged in then set a 7 second popup to show
    // the newsletter
    if (localStorage.getItem("access_token")) return;

    if (localStorage.getItem("preventNewsletterPopup") == "1") return;

    setTimeout(() => {
      setNewsletterVisible(true);
    }, 30000);
  }, []);

  const onSubmit = (params) => {
    const { email } = params;

    graphQLCall(
      `
      mutation SignupToNewsletter($input: SubscribeToNewsletterInput!) {
        subscribeToNewsletter(input: $input) {
          success
        }
      }
    `,
      {
        input: {
          email: email,
        },
      }
    ).then((result) => {
      if (!result.success) return;
      setNewsletterVisible(false);
      // So that for the same user we don't show the popup again unless
      // they clear their browser data
      localStorage.setItem("preventNewsletterPopup", "1");

      var { close } = showModal(
        <div className={classes.popupContainer}>
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 50,
            }}
          >
            <p>Thank you for choosing to receive updates from ClassicGT</p>
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              className={classes.okButton}
              onClick={() => {
                close();
              }}
            >
              Close
            </button>
          </div>
        </div>
      );
    });
  };

  const onClose = () => {
    setNewsletterVisible(false);

    console.log("closed newsletter popup and saved storage item");
    // So that for the same user we don't show the popup again unless
    // they clear their browser data
    localStorage.setItem("preventNewsletterPopup", "1");
  };

  return (
    <Context.Provider value={{}}>
      {child}
      {newsletterPopup ? (
        <NewsletterPopUp onSubmit={onSubmit} onClose={onClose} />
      ) : null}
    </Context.Provider>
  );
};

const styles = (theme) => ({
  okButton: {
    backgroundColor: "#12B200",
    color: "white",
    fontSize: 18,
    height: 36,
    borderRadius: 2,
    border: "none",
    cursor: "pointer",
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 10,
    [theme.breakpoints.up("sm")]: {
      width: 140,
    },
  },
  popupContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },
});

export default withStyles(styles)(NewsletterPopupHOC);
