import axios from "axios";
import constants, { param } from "./constants";
import { toast } from "react-toastify";
import config from "./../../config";

export const timeOut = 5000;

export const createNotification = (type, msg) => {
  if (type === "info") {
    toast.info(msg);
  } else if (type === "success") {
    toast.success(msg);
  } else if (type === "warning") {
    toast.warn(msg);
  } else {
    toast.error(msg);
  }
};

const accessTokenVerify = async () => {
  if (
    parseInt(localStorage.getItem("expiry")) <
    parseInt(Date.now() / 1000) - 10
  ) {
    await axios
      .post(constants.token, { token: localStorage.getItem("refresh_token") })
      .then((response) => {
        if (
          response &&
          response.status &&
          response.status === 200 &&
          response.data.status
        ) {
          localStorage.setItem("access_token", response.data.accessToken);
          localStorage.setItem("refresh_token", response.data.refreshToken);
          localStorage.setItem("expiry", parseInt(Date.now() / 1000) + 240);
        } else {
          createNotification("error", response.error);
        }
      });
  }
};

const makeByPublishPages = [
  "/",
  "/Marketplace",
  "/marketplace/search",
  "/marketplace/all",
];

const shouldUseMakeByPublish = () => {
  return makeByPublishPages.includes(window.location.pathname);
};

export const login = async (bodyData) => {
  return await axios.post(constants.login, bodyData);
};

export const loginFB = async () => {
  return await axios.get(constants.loginFB);
};

export const loginGoogle = async () => {
  return await axios.get(constants.loginGoogle);
};

export const logout = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  const data = {
    token: localStorage.getItem("refresh_token"),
  };
  return await axios.delete(constants.logout, { data, headers });
};

export const register = async ({ redirectFlags, ...rest }) => {
  return await axios.post(constants.register, rest, {
    ...(redirectFlags && { params: { redirectFlags } }),
  });
};

export const changePassword = async (bodyData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  return await axios.post(constants.changePassword, bodyData, { headers });
};

export const AdvertiseWithUs = async (bodyData) => {
  return await axios.post(constants.AdvertiseWithUs, bodyData);
};

export const getadvertise = async (param, carId = null) => {
  return await axios.get(
    constants.getadvertise +
      "?page_slug=" +
      param +
      (carId ? `&carId=${carId}` : "")
  );
};

export const contactUs = async (bodyData) => {
  return await axios.post(constants.contactUs, bodyData);
};

export const profile = async (bodyData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.post(constants.profile, bodyData, { headers });
};

export const profileDetails = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.get(constants.profile, { headers });
};

const needFilterCountPages = [
  "/search-price-trend",
  "/add-watchlist",
  "/price-trends",
];

const shouldAddFilterCount = () => {
  return needFilterCountPages.includes(window.location.pathname);
};

export const carMakeList = async () => {
  // Include the current pathname in the cache key
  const cacheKey = "carMakeListResponse_" + window.location.pathname;
  const cachedData = localStorage.getItem(cacheKey);
  const now = new Date();

  if (cachedData) {
    const { timestamp, response } = JSON.parse(cachedData);
    const age = now - new Date(timestamp);
    if (age < 24 * 60 * 60 * 1000) {
      // Cache for 24 hours
      return response;
    }
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };

  const usingEndpoint = shouldUseMakeByPublish()
    ? constants.carMakeListByPublish
    : constants.carMakeList;

  const params = shouldAddFilterCount() ? { filterCount: true } : {};

  const res = await axios.get(usingEndpoint, {
    headers,
    params,
  });
  // Save to cache
  localStorage.setItem(
    cacheKey,
    JSON.stringify({ timestamp: now, response: res })
  );

  return res;
};

export const carModelandVariant = async (bodyData) => {
  const usingEndpoint = shouldUseMakeByPublish()
    ? constants.carModelandVariantByPublish
    : constants.carModelandVariant;
  return await axios.post(usingEndpoint, bodyData);
};

export const getCarModelPublish = async (params) => {
  return axios.get(constants.carModelByPublish, {
    params: {
      ...params,
    },
  });
};

export const getCarVariantPublish = async (params) => {
  return axios.get(constants.carVariantByPublish, {
    params: {
      ...params,
    },
  });
};

export const getCarDerivativeePublish = async (params) => {
  return axios.get(constants.carDerivativeByPublish, {
    params: {
      ...params,
    },
  });
};

export const getCarModel = async (params) => {
  return axios.get(constants.carModel, {
    params: {
      ...params,
    },
  });
};

export const getCarVariant = async (params) => {
  return axios.get(constants.carVariant, {
    params: {
      ...params,
    },
  });
};

export const getCarDerivate = async (params) => {
  return axios.get(constants.carDerivative, {
    params: {
      ...params,
    },
  });
};

export const addCarWatchList = async (bodyData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.post(constants.addCarWatchList, bodyData, { headers });
};

export const allCarWatchList = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.get(constants.allCarWatchList, { headers });
};

export const deleteCarWatchList = async (data) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.delete(constants.deleteCarWatchList, { data, headers });
};

export const editMyWatchlist = async (param) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.get(constants.editMyWatchlist + "?id=" + param, {
    headers,
  });
};

export const editMyWatchlistData = async (bodyData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.post(constants.editMyWatchlistData, bodyData, { headers });
};

export const allMyCar = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.get(constants.allMyCar, { headers });
};

export const myCar = async (bodyData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.post(constants.myCar, bodyData, { headers });
};

export const editMyCar = async (param) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.get(constants.editMyCar + "?id=" + param, { headers });
};

export const editMyCarData = async (bodyData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.post(constants.editMyCarData, bodyData, { headers });
};

export const deleteMyCar = async (data) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.delete(constants.deleteMyCar, { data, headers });
};

export const appreciatingClassicCar = async () => {
  return await axios.get(constants.appreciatingClassicCar + "?limit=16");
};

export const trendGraph = async ({ token, ...rest }) => {
  const bearerToken = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + (token || bearerToken),
  };
  return await axios.post(constants.trendGraph, rest, { headers });
};

export const trendGraphName = async (bodyData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };

  // await accessTokenVerify()
  return await axios.post(constants.trendGraphName, bodyData, { headers });
};

export const totalCarsCount = async (bodyData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  // await accessTokenVerify()
  return await axios.post(constants.totalCarsCount, bodyData, { headers });
};

export const myWatchListChart = async (bodyData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  return await axios.post(constants.myWatchListChart, bodyData, { headers });
};

export const forgetpassword = async (bodyData) => {
  return await axios.post(constants.forgetpassword, bodyData);
};

export const uploadMyCarIamge = async (bodyData) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    // 'Authorization': "bearer " + localStorage.getItem("access_token")
  };
  return await axios.put(constants.s3secureurl, bodyData, { headers });
};

/**
 * Upload an image to S3
 * @param {File} file
 * @returns {String} Returns the path to the uploaded image
 */
export const uploadImage = async (file) => {
  let url = await fetch(`${constants.imgUpload}?name=${file.name}`).then(
    (res) => res.json()
  );
  await fetch(url.secureURL, {
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: file,
    mode: "cors",
  });
  return url.secureURL.split("?")[0];
};

/**
 * Call the graphQL endpoint
 * @param {string} query
 * @param {object} variables
 */
export const graphQLCall = async (query, variables) => {
  var headers = {};
  if (localStorage.getItem("access_token")) {
    headers["Authorization"] = "Bearer " + localStorage.getItem("access_token");
  }

  return fetch(config.graphQLUrl, {
    method: "post",
    headers,
    body: JSON.stringify({
      query: query,
      variables: variables,
    }),
  });
};

export const shareLinkPriceTrend = async (bodyData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("access_token"),
  };
  return await axios.post(constants.sharePriceTrend, bodyData, { headers });
};
