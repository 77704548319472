import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  "@global": {
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    ".Mui-focused": {
      //border: "1px black solid !important",
    },
    ".MuiSelect-select:focus": {
      backgroundColor: "white",
    },
    ".MuiInput-underline:hover": {
      border: "0px !important ",
    },
    ".MuiInput-input": {
      border: "1px black solid",
    },
    ".text-white": {
      color: theme.palette.common.white,
    },
    ".icon-style": {
      color: theme.palette.common.white,
      [theme.breakpoints.down("sm")]: {
        color: theme.palette.common.darkBlack,
      },
    },
    ".menu-icon-style": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
        width: "10%",
        color: theme.palette.common.darkBlack,
        marginRight: "8px",
      },
    },
    ".icon-style1": {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.common.darkBlack,
    },
    ".icon-sytl": {
      marginRight: "15px",
    },
    ".listItemLeftPadding": {
      paddingTop: `${theme.spacing(1.75)}px !important`,
      paddingBottom: `${theme.spacing(1.75)}px !important`,
      paddingLeft: `${theme.spacing(4)}px !important`,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: `${theme.spacing(4)}px !important`,
      },
      "@media (max-width:  420px)": {
        paddingLeft: `${theme.spacing(1)}px !important`,
      },
    },
    ".container": {
      width: "100%",
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      marginRight: "auto",
      marginLeft: "auto",
      [theme.breakpoints.up("sm")]: {
        maxWidth: 960,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 1280,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1170,
      },
    },
    ".row": {
      display: "flex",
      flexWrap: "wrap",
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2),
    },
    /*".bodyContent": {
      width: "100%",
      // paddingRight: theme.spacing(4),
      // paddingLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        maxWidth: 960,
      },
      [theme.breakpoints.up("md")]: {
        // maxWidth: 1280,
        //marginTop: theme.spacing(8),
        maxWidth: `calc(100% - ${theme.spacing(8)}px)`,
      },
      [theme.breakpoints.up("lg")]: {
        //marginTop: theme.spacing(10),
        maxWidth: `calc(100% - ${theme.spacing(8)}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        //marginTop: theme.spacing(9),
        marginRight: theme.spacing(0),
        marginLeft: theme.spacing(0),
      },
    },*/
    ".container-fluid": {
      width: "100%",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginRight: "auto",
      marginLeft: "auto",
      maxWidth: 1370,
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1280,
      },
    },
    ".lg-mg-top": {
      marginTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: `${theme.spacing(14)}px !important`,
      },
    },
    ".lg-mg-bottom": {
      marginBottom: `${theme.spacing(2)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginBottom: `${theme.spacing(2)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: `${theme.spacing(2)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: `${theme.spacing(2)}px !important`,
      },
    },
    ".lg-p-top": {
      paddingTop: `${theme.spacing(10)}px !important`,
      [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(10)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(10)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: `${theme.spacing(8)}px !important`,
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: `${theme.spacing(12)}px !important`,
      },
    },
    ".lg-p-top1": {
      paddingTop: `${theme.spacing(3)}px !important`,
      [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(6)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(5)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: `${theme.spacing(4.5)}px !important`,
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: `${theme.spacing(6)}px !important`,
      },
    },
    ".sm-p-top": {
      [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(4)}px !important`,
      },
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
