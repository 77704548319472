import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LoadingSpinner from "./../../shared/components/LoadingSpinner";

const { createContext, useContext, useState } = React;

const Context = createContext({});
export const useBlockUIContext = () => useContext(Context);

const BlockUI = (props) => {
  const { children, classes, ...otherProps } = props;
  const [showBlockUI, setShowBlockUI] = useState(false);

  const child = React.cloneElement(children, otherProps);

  const setBlockUI = (enabled) => {
    setShowBlockUI(enabled);
  };

  return (
    <Context.Provider
      value={{
        setBlockUI,
      }}
    >
      <div className={classes.container}>
        {child}

        {showBlockUI ? (
          <div className={classes.loadingContainer}>
            <LoadingSpinner large />
          </div>
        ) : null}
      </div>
    </Context.Provider>
  );
};

const styles = (theme) => ({
  container: {
    position: "relative",
  },
  loadingContainer: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
    backgroundColor: "grey",
    zIndex: 2500,
  },
});

export default withStyles(styles)(BlockUI);
