import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const { createContext, useContext, useState } = React;

const Context = createContext({});
export const useModalContext = () => useContext(Context);

const Modal = (props) => {
  const { children, classes, ...otherProps } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [customCloseButton, setCustomCloseButton] = useState(null);

  const child = React.cloneElement(children, otherProps);

  const showModal = (content, customClose) => {
    setModalContent(content);
    setModalVisible(true);

    if (customClose) {
      setCustomCloseButton(customClose);
    }
    return {
      close: closeModal,
    };
  };

  const closeModal = () => {
    setModalContent(null);
    setModalVisible(false);
  };

  return (
    <Context.Provider
      value={{
        showModal,
      }}
    >
      <>
        {child}
        {modalVisible ? (
          <div className={classes.modalContainer}>
            <div className={classes.overlay}></div>
            <div className={classes.boxContainer}>
              {customCloseButton && customCloseButton}
              {!customCloseButton && (
                <div
                  className={classes.closeIconContainer}
                  onClick={closeModal}
                >
                  <CloseIcon className={classes.closeIcon} />
                </div>
              )}
              <div className={classes.content}>{modalContent}</div>
            </div>
          </div>
        ) : null}
      </>
    </Context.Provider>
  );
};

const styles = (theme) => ({
  modalContainer: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: "#000000",
    opacity: 0.3,
  },
  boxContainer: {
    width: 640,
    maxWidth: "90%",
    minHeight: 308,
    maxHeight: "90vh",
    backgroundColor: "white",
    zIndex: 99,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.5)",
    borderRadius: 4,
    position: "relative",
    display: "flex",
  },
  closeIconContainer: {
    width: 20,
    height: 20,
    backgroundColor: "#000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 20,
    right: 20,
    borderRadius: "50%",
    cursor: "pointer",
  },
  closeIcon: {
    color: "white",
    fill: "white",
  },
  content: {
    //display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
  },
});

export default withStyles(styles)(Modal);
