import config from "./../../config";
import { COUNTRY_LIST } from "./../../pages/SellMyCar/view/util";

const { enableMarketPlace } = config;

//"http://localhost:5001/dev/api/v1/"
const host = config.apiUrl + "api/v1/";

const constants = {
  token: host + "user/token",
  login: host + "user/login",
  loginFB: host + "user/fb",
  loginGoogle: host + "user/google",
  myCar: host + "user/my-car",
  logout: host + "user/logout",
  changePassword: host + "user/changepassword",
  profile: host + "user/profile",
  register: host + "user/register",
  contactUs: host + "misc/contact",
  carMakeList: host + "cars/make",
  carMakeListByPublish: host + "cars/make_by_publish",
  allMyCar: host + "user/my-cars",
  allCarWatchList: host + "user/my-watchlist",
  carDetails: host + "cars/detail",
  myCarEdit: host + "user/my-car-edit",
  trendGraph: host + "cars/price-trend-chart",
  trendGraphName: host + "cars/price-trend-charts",
  addCarWatchList: host + "user/my-watchlist",
  AdvertiseWithUs: host + "misc/advertising-form",
  carModelandVariant: host + "cars/model_variant",
  carModelandVariantByPublish: host + "cars/model_variant_by_publish",
  appreciatingClassicCar: host + "cars/appr_classic",
  deleteCarWatchList: host + "user/my-watchlist/delete",
  deleteMyCar: host + "user/my-car/delete",
  editMyCar: host + "user/my-car",
  editMyCarData: host + "user/my-car-edit",
  editMyWatchlist: host + "user/my-watchlist-edit",
  editMyWatchlistData: host + "user/my-watchlist-edit",
  getadvertise: host + "misc/customcreatives",
  myWatchListChart: host + "user/my-watchlist/chart",
  totalCarsCount: host + "cars/totalcars",
  forgetpassword: host + "user/forgetpassword",
  imgUpload: host + "user/s3-secure-url",
  carModelByPublish: host + "cars/model_by_publish",
  carVariantByPublish: host + "cars/variant_by_publish",
  carDerivativeByPublish: host + "cars/derivative_by_publish",
  carModel: host + "cars/model",
  carVariant: host + "cars/variant",
  carDerivative: host + "cars/derivative",
  sharePriceTrend: host + "cars/generate-link-price-trend-chart",
};

export const noRedirect = [
  "/login",
  "/signup",
  "/",
  "/watchlist",
  "/my-cars",
  "/advertise",
];

export const notificationMsg = {
  registerSucess: "Your sign up was successful!",
  addMyCarSucess: "Car added sucessfully",
  profileSucess: "Your profile has been updated sucessfully",
  addCarWatchListSucess: "Car Added to Watchlist sucessfully",
  loginSucess: "You have logged in successfully, welcome!",
  logoutSucess: "You have logged out successfully",
  contactUsSucess: "Your Message has been submited sucessfully",
  // noData: "No data found. Please update search parameter.",
  noData: "No cars found, change or reduce your search criteria",
  noData1: "No data found in this region, please select another region.",
  error: "Sorry, something went wrong, please try again.",
  notLogin: "You are not loggedin.",
  copySuccess: "Copied to clipboard",
  profileCreated: "Your profile has been created!",
  profileDealerCreated: "Thank you, looking forward to working with you",
};

export const param = {
  "home-price-trend": "/home",
  "home-watchlist": "/watchlist",
  "home-mycars": "/my-cars",
  login: "/login",
  "about-us": "/about-us",
  profile: "my-profile",
  "search-price-trend": "search-price-trend",
  "add-watchlist": "add-watchlist",
  "add-mycars": "add-my-car",
  "price-trend-chart": "TrendGraph",
  "change-password": "change-password",
};

export const dropdownItem = {
  // purchase_location: ["UK", "USA"],
  purchase_location: enableMarketPlace
    ? COUNTRY_LIST
    : ["United Kingdom", "United States", "Worldwide"],
  searchLocations: ["United Kingdom", "United States", "Worldwide"],
  purchase_month: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  transmission: ["Manual", "Auto"],
  driving_side: {
    RHD: "Right Hand Drive",
    LHD: "Left Hand Drive",
  },
  condition: ["Project", "Good", "Excellent"],
  // "Any Condition"
  distance_unit: ["KM", "M"],
  currency: "GBP",
  year_from: 1950,
  current_year: new Date().getFullYear(),
};

export const demands = {
  0: {
    classes: "neutralDemand",
    value: "Netural",
  },
  1: {
    classes: "red",
    value: "",
  },
  2: {
    classes: "warmDemand",
    value: "Warm",
  },
  3: {
    classes: "red",
    value: "",
  },
  4: {
    classes: "highDemand",
    value: "High",
  },
};

export const pageSlug = {
  aboutUs: "about-us",
  termOfUse: "about-us",
  privacyPolicy: "about-us",
  changePassword: "change-password",
  priceTrendChart: "price-trend-chart",
  priceTrendLandingPage: "price-trend-landingpage",
  myCarsInfo: "add-mycars",
  watchListAlert: "add-watchlist",
  myProfile: "profile",
  login: "login",
  loggedinHome: "home-member",
  myCars: "home-mycars",
  watchlist: "home-watchlist",
  pricetrend: "home-price-trend",
  pricetrendLandingPage: "price-trend",
  marketplace: "marketplace",
  carDetailsPageSectionOne: "car-details-page-section-one",
  carDetailsPageSectionTwo: "car-details-page-section-two",
  carDetailsPageSectionThree: "car-details-page-section-three",
  carDetailsPageSectionFour: "car-details-page-section-four",
};

export const FONT_SIZE = {
  TITLE: 20,
  TEXT_LARGE: 18,
  TEXT_MEDIUM: 16,
  TEXT_SMALL: 14,
};

export const COLORS = {
  GREY_BACKGROUND: "#F1F1F1",
  GREY_SEPARATOR: "#CDCDCD",
  PRIMARY_ACTION: "#12B200",
  ACTION_DISABLED: "#bfbfbf",
  INCIDENTAL_LINK: "#6A3AE0",
  BRAND_RED: "#CC0000",
  FOOTER_BACKGROUND: "#000",
  TEXT_BLACK: "#000",
  TEXT_WHITE: "#FFF",
  BLACK: "#000",
  TEXT_BORDER: "#cacaca",
  TEXT_BACKGROUND: "#FFF",

  WHITE: "#fff",
};

export default constants;
