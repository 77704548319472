
// Anything added here also needs to be added to flavour.js

export default {
  apiUrl: "https://api.classic-gt.co.uk/",
  graphQLUrl: "https://api.classic-gt.co.uk/graphql",
  // Used as a feature flag to see the marketplace support
  enableMarketPlace: true,
};

    