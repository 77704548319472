import React from "react";
import { useEffect, useState } from "react";
import { graphQLCall } from "../shared/config/axios";

const ANALYTICS_MUTATION = `
mutation CreateAnalyticsEvent($input: CreateAnalyticsEventInput!) {
  createAnalyticsEvent(input: $input) {
    collectionName
    action
    dateStamp
    collectionId
  }
}
`;
export const useAnalyticsEvents = () => {
  const analyticsViewListing = async (carId) => {
    const collectionName = "usermycars";
    const collectionId = carId;
    const action = "VIEW_LISTING";

    graphQLCall(ANALYTICS_MUTATION, {
      input: {
        collectionName: collectionName,
        collectionId: collectionId,
        action: action,
        dateStamp: new Date(),
      },
    });
  };

  const analyticsPhoneReveal = (carId) => {
    const collectionName = "usermycars";
    const collectionId = carId;
    const action = "PHONE_REVEAL";

    graphQLCall(ANALYTICS_MUTATION, {
      input: {
        collectionName: collectionName,
        collectionId: collectionId,
        action: action,
        dateStamp: new Date(),
      },
    });
  };

  const analyticsSendEnquiry = (carId, data = {}) => {
    const collectionName = "usermycars";
    const collectionId = carId;
    const action = "SEND_ENQUIRY";

    graphQLCall(ANALYTICS_MUTATION, {
      input: {
        collectionName: collectionName,
        collectionId: collectionId,
        action: action,
        dateStamp: new Date(),
        data: JSON.stringify(data),
      },
    });
  };

  const analyticsCarDetailsAd = (carId, data = {}) => {
    const collectionName = "usermycars";
    const collectionId = carId;
    const action = "CAR_DETAILS_ADVERTISING_CLICK";

    graphQLCall(ANALYTICS_MUTATION, {
      input: {
        collectionName: collectionName,
        collectionId: collectionId,
        action: action,
        dateStamp: new Date(),
        data: JSON.stringify(data),
      },
    });
  };

  const analyticsPriceTrendAd = (data = {}) => {
    const collectionName = "pricetrendlandingpage";
    const action = "PRICE_TREND_LANDING_ADVERTISING_CLICK";
    graphQLCall(ANALYTICS_MUTATION, {
      input: {
        collectionName: collectionName,
        collectionId: data?.url,
        action: action,
        dateStamp: new Date(),
        data: JSON.stringify(data),
      },
    });
  };

  const analyticsHomePageAd = (data = {}) => {
    const collectionName = "homepage";
    const action = "HOME_PAGE_ADVERTISING_CLICK";

    graphQLCall(ANALYTICS_MUTATION, {
      input: {
        collectionName: collectionName,
        collectionId: data?.url,
        action: action,
        dateStamp: new Date(),
        data: JSON.stringify(data),
      },
    });
  };

  const getAnalyticsEvents = async (carIds) => {
    const res = await graphQLCall(ANALYTICS_MUTATION, {
      collectionId: carIds ?? [],
    });

    const resJSON = await res.json();
    const result = resJSON.data?.searchAnalytics ?? [];

    return result;
  };

  return {
    analyticsPhoneReveal: analyticsPhoneReveal,
    analyticsViewListing: analyticsViewListing,
    analyticsSendEnquiry: analyticsSendEnquiry,
    analyticsCarDetailsAd: analyticsCarDetailsAd,
    getAnalyticsEvents: getAnalyticsEvents,
    analyticsHomePageAd: analyticsHomePageAd,
    analyticsPriceTrendAd: analyticsPriceTrendAd,
  };
};
