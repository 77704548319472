export const COUNTRY_LIST = [
  "Australia",
  "Austria",
  "Bahrain",
  "Belgium",
  "Brazil",
  "Canada",
  "Croatia",
  "Cyprus",
  "Denmark",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Guernsey",
  "Hong Kong",
  "Ireland",
  "Isle of Man",
  "Italy",
  "Japan",
  "Jersey",
  "Luxembourg",
  "Malta",
  "Monaco",
  "Netherlands",
  "New Zealand",
  "Norway",
  "Poland",
  "Portugal",
  "Qatar",
  "Saudi Arabia",
  "Singapore",
  "South Africa",
  "Spain",
  "Sweden",
  "Switzerland",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
];

export const REGION_LIST = {
  "United Kingdom": [
    "London",
    "North East",
    "North West",
    "Yorkshire",
    "East Midlands",
    "West Midlands",
    "South East",
    "East of England",
    "South West",
  ].sort(),
};
