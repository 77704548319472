import React from "react";
import classNames from "classnames";
import { addStyle } from "./../../util/dom";

addStyle(`
  
  .Spinner {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
  }
  .Spinner.large {
    width: 80px;
    height: 80px;
  }
  .Spinner div {
    transform-origin: 10px 10px;
    animation: loading-spinner 1.2s linear infinite;
  }
  .Spinner.large div {
    transform-origin: 40px 40px;
  }
  .Spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 1px;
    left: 9px;
    width: 2px;
    height: 5px;
    border-radius: 20%;
    background: #000000;
  }
  .Spinner.large div:after {
    top: 5px;
    left: 36px;
    width: 8px;
    height: 20px;
    border-radius: 20%;
    background: #000000;
  }
  .Spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .Spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .Spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .Spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .Spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .Spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .Spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .Spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .Spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .Spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .Spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .Spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes loading-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`);

const LoadingSpinner = (props) => {
  const { large } = props;

  return (
    <div
      className={classNames({
        Spinner: true,
        large: large,
      })}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingSpinner;
