import { Button, Input } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";
import CarPorche from "../../assets/image/porsches.jpeg";

const styles = (theme) => ({
  container: {
    background: "#fff",
    zIndex: 2,
    position: "fixed",
    display: "flex",
    flexDirection: "column-reverse",
    maxWidth: "95%",
    borderRadius: 3,
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  background: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    paddingTop: 20,
    top: 0,
    left: 0,
    //width: "100vw",
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2000,
    //[theme.breakpoints.up("md")]: {
    alignItems: "center",
    //},
  },
  title: {
    fontSize: 22,
    color: "#1e1e1e",
  },
  banner: {
    width: "100%",
    height: 200,
    backgroundSize: "cover",
    backgroundPosition: "50%",
    [theme.breakpoints.up("md")]: {
      height: "inherit",
      width: "60%",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: 40,
    paddingTop: 30,
    width: "100%",
    gap: 10,
    [theme.breakpoints.up("md")]: {
      padding: 40,
      width: "40%",
    },
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
    backgroundColor: "rgba(0,0,0,0.5)",
    color: "white",
    width: 25,
    height: 25,
    borderRadius: "50%",
    border: "none",
  },
  greenButton: {
    backgroundColor: "#43bb00",
    fontSize: 14,
    color: "white",
    maxWidth: 160,
    textTransform: "none",
  },
  label: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
    color: "#1e1e1e",
  },
  terms: {
    fontSize: 12,
    color: "#8c8c8c",
  },
  links: {
    color: "#8c8c8c",
  },
  email: {
    width: "100%",
    borderWidth: 1,
    padding: 10,
    marginTop: 5,
    borderRadius: 5,
    borderColor: "rgba(0,0,0,.1)",
    background: "#fafafa",
    fontSize: 16,
  },
});

const NewsLetterPopUpComponent = ({
  title,
  closeWindow,
  backgroundImage,
  classes,
  onSubmit,
  onEmailChange,
}) => {
  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div className={classes.content}>
          <strong className={classes.title}>{title}</strong>
          <label className={classes.label}>
            Email Address
            <input
              className={classes.email}
              type="email"
              onChange={(event) => onEmailChange(event.target.value)}
              placeholder="Your email address"
            />
          </label>
          <p className={classes.terms}>
            By signing up you are indicating that you would like to receive news
            and promotions from ClassicGT and you agree to our{" "}
            <a className={classes.links} href="/terms-of-use">
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a className={classes.links} href="/privacy-policy">
              Privacy Policy
            </a>
            .
          </p>
          <Button
            className={classes.greenButton}
            onClick={onSubmit}
            style={{
              // This is a work around to avoid the material UI grey colour
              // applying on hover
              backgroundColor: "#43bb00",
            }}
          >
            Send Me Updates
          </Button>
        </div>
        <div
          className={classes.banner}
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <button onClick={closeWindow} className={classes.closeButton}>
            x
          </button>
        </div>
      </div>
    </div>
  );
};

const NewsletterPopUp = withStyles(styles, { withTheme: true })(
  NewsLetterPopUpComponent
);

const Container = (props) => {
  const { onClose, onSubmit } = props;
  const [email, setEmail] = React.useState("");

  return (
    <NewsletterPopUp
      onSubmit={() => onSubmit({ email })}
      onEmailChange={setEmail}
      title={
        "Be the first to know about classic & sports car trends and prices"
      }
      backgroundImage={CarPorche}
      closeWindow={onClose}
    />
  );
};

export default Container;
